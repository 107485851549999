// import menuImage from '../assets/image/menu-image.webp'

export const menuItems = [
        {
          name: "#content",
          label: "課程內容",
          isExternal: false,
        },
        {
          name: "#buy",
          label: "開辦日期",
          isExternal: false,
        },
        {
           name: "#instructor",
           label: "導師簡介",
           isExternal: false,
        },
        {
          name: "#faq",
          label: "常見問題",
          isExternal: false,
       },
       ];
export default menuItems;