//import siteLogo from "~image/appcoda-logo.png"
import React from 'react'
import HeaderButton from "~components/Core/Header/InnerPageHeader/HeaderButton"

const headerDefaultConfig = {
    headerClasses: "site-header site-header--menu-center light-header position-relative",
    containerFluid: true,
    darkLogo:false,
    //defaultLogo: siteLogo,
    buttonBlock:(<a href="/#buy"><HeaderButton btnText="立即報名"/></a>)
}
export default  headerDefaultConfig